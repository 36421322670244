<template>
   <ObiText class="obi-toolbar" :class="[title && '--with-title']">
      <slot name="title">
         <ObiTitle class="obi-toolbar-title" v-if="title" :title="title" />
      </slot>
      <ObiText class="obi-toolbar-search" v-if="!hideSearch">
         <slot name="search">
            <ObiInput
               clean
               compact
               :icon="IconSearch"
               @change="onActionSearch"
               wrapper-class="for-toolbar"
               v-model="formDataSearch.query"
               :placeholder="$t('search_input_placeholder')"
               :errors="formDataSearch.errors.get('query')"
            />

            <ObiButton
               color="primary"
               @click="onActionSearch"
               icon="mdi mdi-magnify"
               :text="$t('button_search_label')"
            />
         </slot>
      </ObiText>
      <div class="obi-toolbar-actions" v-if="!hideActions">
         <slot name="actions">
            <ctk-date-time-picker
               v-if="!hideDatePicker"
               ref="datePicker"
               :no-value-to-custom-elem="true"
               locale="tr"
               range
               color="#2F71F2"
               button-color="#2F71F2"
               v-model="formDataSearch.date_range"
               format="DD.MM.YYYY"
               outputFormat="YYYY-MM-DD HH:mm:ss"
               :custom-shortcuts="shortcuts"
               @validate="onActionSearch()"
            >
               <ObiInput clean compact readonly icon="mdi mdi-calendar" :placeholder="getSelectedDateRange" />
            </ctk-date-time-picker>

            <ObiButton v-if="!hideActionButtons" color="lighter" icon="mdi mdi-printer" :click="onActionPrint" />
            <ObiButton v-if="!hideActionButtons" color="lighter" icon="mdi mdi-download" :click="onActionDownLoad" />
         </slot>
      </div>
   </ObiText>
</template>

<script>
import Form from '@/libs/form';

import IconSearch from '@/components/Icons/header/IconSearch.svg';

export default {
   name: 'ObiToolbar',
   props: {
      title: {
         type: String,
         default: null,
      },
      icon: {
         type: [String, Object],
      },
      actionSearch: {
         type: Function,
      },
      actionPrint: {
         type: Function,
      },
      actionDownLoad: {
         type: Function,
      },
      hideSearch: {
         type: Boolean,
         default: false,
      },
      hideActions: {
         type: Boolean,
         default: false,
      },
      hideActionButtons: {
         type: Boolean,
         default: false,
      },
      hideDatePicker: {
         type: Boolean,
         default: false,
      },
   },
   i18n: {
      messages: {
         tr: {
            search_input_label: 'Arama',
            search_input_placeholder: 'Arama',
            button_search_label: 'Ara',
            date_input_placeholder: 'Ağustos 21, 2019 - Eylül 21, 2020',
            button_help_label: 'Yardım',
         },
         en: {
            search_input_label: 'Search',
            search_input_placeholder: 'Search',
            button_search_label: 'Search',
            date_input_placeholder: 'Ağustos 21, 2019 - Eylül 21, 2020',
            button_help_label: 'Help',
         },
      },
   },
   computed: {
      getSelectedDateRange() {
         return this.formDataSearch.date_range
            ? `${this.formDataSearch.date_range.start}-${this.formDataSearch.date_range.end}`
            : 'Bugün';
      },
   },
   mounted() {},
   data() {
      return {
         IconSearch,
         formDataSearch: Form.create(['query', 'date_range']),
         shortcuts: [
            { key: 'thisWeek', label: 'Bu Hafta', value: 'isoWeek' },
            { key: 'lastWeek', label: 'Geçen Hafta', value: '-isoWeek' },
            { key: 'last7Days', label: 'Son 7 Gün', value: 7 },
            { key: 'last30Days', label: 'Son 30 Gün', value: 30 },
            { key: 'thisMonth', label: 'Bu Ay', value: 'month' },
            { key: 'lastMonth', label: 'Geçen Ay', value: '-month' },
            { key: 'thisYear', label: 'Bu Yıl', value: 'year' },
            { key: 'lastYear', label: 'Geçen Yıl', value: '-year' },
         ],
      };
   },
   methods: {
      onActionSearch() {
         const params = {
            query: this.formDataSearch.query,
            start_at: this.formDataSearch.date_range ? this.formDataSearch.date_range.start : null,
            end_at: this.formDataSearch.date_range ? this.formDataSearch.date_range.end : null,
         };

         this.$router.push({ query: params });
         this.$emit('actionSearch');
      },

      onActionPrint() {
         if (this.$props.actionPrint) {
            return this.$props.actionPrint();
         }

         //  Default action callback
      },

      onActionDownLoad() {
         if (this.$props.actionDownLoad) {
            return this.$props.actionDownLoad();
         }

         //  Default action callback
      },

      onActionFilter() {
         if (this.$props.onActionFilter) {
            return this.$props.onActionFilter();
         }

         //  Default action callback
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-toolbar {
   height: 82px;
   display: grid;
   grid-gap: 1rem;
   padding: 22px 24px;
   align-items: center;
   background-color: #fff;
   border-top-left-radius: 5px;
   justify-content: space-between;
   grid-template-columns: 1fr auto;

   &.--with-title {
      grid-template-columns: 1fr auto auto;
   }

   .obi-toolbar-title {
      color: $primary;
      font-size: 1.25rem;
   }

   .obi-toolbar-search {
      display: grid;
      grid-gap: 10px;
      justify-content: start;
      grid-auto-flow: column;

      .obi-input {
         min-width: 330px;
      }
   }

   .obi-toolbar-actions {
      display: grid;
      grid-gap: 10px;
      justify-content: start;
      grid-auto-flow: column;

      .obi-input {
         min-width: 300px;
      }
   }
}
</style>
